
  .card {
    padding-bottom: 1.5em;
    border: 1.2px solid black;
    box-shadow: none;
    background: none;
  }
  .card-content {
    height: 15em;
    i{
      font-size: 30px;
    }
  }
  .card-content__img {
    width: 5rem;
    border-radius: 50%;
  }
  .btn-small {
    background-color: white;
    border: 1px solid var(--night);
    box-shadow: none;
    color: var(--white);
    img {
      margin-right: 0.8em;
    }
    a:link :active :visited  { 
      color: var(--white);
    }
  }
  .btn-small:focus {
    background-color: white;
  }
  .btn-small:hover {
    transform: translateY(-0.4em) scale(1.12);
    background-color: var(--mavi);
  }
  .logos {
    height: 6rem;
    border-radius: 50px;

  }