:root {
  --eksicode: #82bd4e;
  --kurbaga-yesili: #99f7ab;
  --light-yesil: #d6f9dd;
  --gri: #60695c;
  --gray-input: #9e9e9e;
  --acikgri: #c5d6d8;
  --night: #0C090A;
  --coral: coral;
  --mavi: #2196f3;
  --blurple: #7289DA;
  --light-mavi:#bbdefb;
  --beyaz:#fff;
}