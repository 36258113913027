body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.container {
  flex: 1 0 auto;
}

.btn-small, .btn-small:hover, .btn-small:focus {
  background-color: var(--eksicode);
}

.eksicode {
  background-color: var(--eksicode) !important;
}

.spinner-eksicode-only {
  border-color: var(--eksicode) !important;
}

.disabled {
  cursor: default;
  color: #cdcdcd;
}

.card-title {
  font-size: 1.2rem !important;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

input[type=text] {
  margin: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
}

.description {
  padding: 2rem 0;
}