.collapsible {
    box-shadow: none;
    border-color: black;
  
    .collapsible-body {
      border-color: black;
      background: #f5f5f5;
      font-size: 1em;
      h6{
        font-weight: 600;
      }
      ul{
        padding-left:20px;
      }
    }
    .collapsible-header {
      border-color: black;
      display: block;
      .row {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
        .job-title {
          margin-bottom: 0.5em;
        }
        .company-name {
          text-align: end;
        }
        .location {
          font-weight: 200;
          font-size: 0.8em;
        }
        .tags {
          margin-top: -1em;
          .chip {
            height: 20px;
            font-size: 10px;
            font-weight: 400;
            line-height: 20px;
            padding: 0 10px;
            border-radius: 10px;
            margin-bottom: 5px;
            margin-right: 5px;
          }
          .job-bg {
            background-color: var(--light-mavi);
          }
        }
      }
    }
  }
  .collapsible-header:focus {
    font-weight: 600;
  }
  