.search {
    label {
        color: var(--eksicode) !important;
    }
    input[type=text]:focus + label {
        color: var(--eksicode) !important;
      }
    input[type=text]:focus {
        border-bottom: 1px solid var(--eksicode) !important;
        box-shadow: 0 1px 0 0 var(--eksicode) !important;
    }
}

.input-field {
    .select-wrapper ul li>span {
        color: var(--eksicode) !important;
    }
    .dropdown-trigger:focus {
        border-bottom: 1px solid var(--eksicode) !important;
    }
}

.kaynaklar li {
    border-bottom: 1px solid #ccc;

    & a {
        display: block;
        padding: .8rem 0rem .8rem .8rem;
    }

    & :hover {
        color: var(--night);
        background: #e5e5e5 !important;
        text-decoration: none;
    }
}

.filters {
    grid-template-columns: auto auto auto auto auto auto;
    display: grid;
    grid-gap: 2rem;
    margin-bottom: 3rem;
}


.paging {
    display: inline-block;
    border: none;
    padding: .5rem .5rem;
    margin: 0px 0px 10px 0px;
    text-decoration: none;
    color: #ffffff;
    font-family: sans-serif;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out, 
                transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.paging:hover,
.paging:focus {
    background: var(--eksicode);
}

.paging:focus {
    outline: 1px solid #fff;
    outline-offset: -4px;
}

.paging:active {
    transform: scale(0.99);
}




.filter__groups {
    grid-column: 1 / 2;
}

.groups__select {
    display: block !important;
    color: var(--gray-input);
    border: 0;
    border-radius: 0;
    outline: 0;
    padding: 0;
    border-bottom: 1px solid var(--gray-input);
    border-bottom-color: var(--gray-input);
    box-shadow: 0px 0px 0 0px var(--eksicode);
    &:hover, &:focus {
        outline: 0;
        color: var(--eksicode);
        border-bottom-color: var(--eksicode);
        box-shadow: 0px 1px 0 0px var(--eksicode);
    }
    transition: all 250ms linear; 
}

.filter__search {
    grid-column: 2 / 7;
}
.filter__search > input {
    border-bottom: 1px solid var(--gray-input) !important;
    box-shadow: 0px 0px 0 0px var(--eksicode) !important;
    &:hover, &:focus {
        outline: 0 !important;
        border-bottom-color: var(--eksicode) !important;
        box-shadow: 0px 1px 0 0px var(--eksicode) !important;
    }
    transition: all 250ms;
}

.no-result {
    font-size: 1.5rem;
}