

a:link { 
  color: var(--eksicode);
}

a:active {
  color: var(--acikgri);
}

a:visited {
  color: var(--night);
}

a:hover {
  color: var(--night);
  text-decoration:underline;
}

h1 {
  font-size: 3rem;
  font-weight: 600;
  @media (max-width: 400px) {
    font-size: 2.5rem;
  }
}
h2 {
  font-size: 2rem;
  font-weight: 600;
}
h4 {
  font-size: 20px;
}

.box {
  height: 0.6rem;
  background-color: var(--acikgri);
  position: absolute;
  z-index: -1;
  margin-left: -1rem;
  margin-top: -2.65rem;
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 200ms;
  &__kaynaklar {
    width: 12.2rem;
  }
  &__projeler {
    width: 10rem;
  }
  &__gruplar {
    width: 20.5rem;
    @media (max-width: 313px) {
      width: 12rem;
      margin-top: -3.65rem;
    }
  }
  &__discord {
    width: 10rem;
  }
  &__kariyer {
    width: 11rem;
    @media only screen and (max-width: 417px){
        width: 14.5rem;
    }
  }
}
h2:hover + .box {
  height: 4rem;
  margin-top: -4.6rem;
  &__kaynaklar {
    background-color: var(--light-yesil);
  }
  &__projeler {
    background-color: var(--kurbaga-yesili);
  }
  &__gruplar {
    background-color: var(--mavi);
    @media (max-width: 313px) {
      height: 5.4rem;
      margin-top: -6.6rem;
    }
  }
  &__discord {
    background-color: var(--blurple);
  }
  &__kariyer {
    background-color: var(--eksicode);
    @media only screen and (max-width: 417px){
        height: 10rem;
        margin-top: -10.8rem;
    }
  }
  
}
.drawings {
  margin-top: -14rem;
  position: relative;
  z-index: -1;
  @media (max-width: 600px) {
    margin-top: 0;
  } 
}
.plane {
  margin-top: -1em;
  margin-right: 5em;
}
.discord {
  margin-left: 5rem;
  width: 20vw;
  @media (max-width: 600px) {
    margin-top: -1em;
    width: 30vw;
  }
}
.eksi {
  padding-bottom: 0;
  .row {
    margin-bottom: 0;
  }
  .entry {
    height: 10rem;
    width: 14rem;
    margin-bottom: 3em;
    p {
      padding: 1em 1em 0 1em;
      font-size: 0.75rem;
      color: black;
    }
    .bubble {
      z-index: -1;
      width: 15rem;
      position: absolute;
    }
    .author {
      font-weight: 600;
      font-size: 0.8rem;
      float: right;
      color: var(--eksicode);
    }
  }
  .people {
    height: 10rem;
  }
}
.break {
  overflow-wrap: break-word;
}